import React from "react";
import WhyChooseDFS_Hero from "../../../assets/img/WhyChooseDFS_Hero.jpg";
import BeingDFSDealer_Thumbnail from "../../../assets/img/BeingDFSDealer_Thumbnail.jpg";
import NationwideLocation_Thumbnail from "../../../assets/img/NationwideLocation_Thumbnail.jpg";

import styles from "../style/styles.module.css";
import { PREVIEW } from "../../../project-config";

export default function WhyChooseDFS() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1 className={styles.title}>Why Choose DFS</h1>
          <p className={styles.toolParag}>
            DFS is your source for the products, marketing tools and service you
            need to profitably meet your customers’ needs.
          </p>
          <img
            className={styles.hero}
            src={WhyChooseDFS_Hero}
            alt="Man on a tablet"
          />

          <p className={styles.productMargin}>
            For 18 years, DFS has supplied thousands of dealers with ever faster
            and easier solutions that help customers manage and grow their
            businesses. The NEW DFSonline.ca continues this tradition, saving
            you valuable time so you can help more customers prosper.
          </p>
          <p>
            <strong>When you partner with DFS, you can expect:</strong>
          </p>

          <ul>
            <li>
              An ever-growing portfolio of products and services at wholesale
              prices
            </li>
            <li>Fast quotes and orders 24/7</li>
            <li>Expert customer service to help you get it right</li>
            <li>Speedy delivery that keeps customers happy and costs low</li>
            <li>
              Easy access to professionally produced marketing materials to help
              you close deals
            </li>
          </ul>
          <p className={styles.productMarginTop}>
            <strong>All so you can do more, sell more and be more.</strong>
          </p>
          <p>
            Please take a moment to explore all that DFS offers, and see how we
            can help you succeed!
          </p>
        </div>
        <div className={styles.sidepanel}>
          <h4>Do business online with DFS</h4>
          <p>See why thousands of Dealers use DFSonline.ca</p>
          <a
            href={`${PREVIEW}/register.html?vid=20090722001&mt=1&ml=en`}
            className={styles.btn}
          >
            Create Online Account
          </a>
          <hr className={styles.toolMargin} />
          <h4>Contact DFS</h4>
          <p>Have a question?</p>
          <p>
            Please call us at{" "}
            <a href="tel:800-490-6327" className={styles.toolColor}>
              800.490.6327
            </a>
          </p>
          <p>
            Or visit our{" "}
            <a href="/contact">
              <span className={styles.toolColor}>Contact Us</span>
            </a>{" "}
            page
          </p>
        </div>
      </div>
      <hr />
      <div className={styles.row}>
        <h2>Explore More About DFS</h2>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img
              src={BeingDFSDealer_Thumbnail}
              alt="Two men shaking hands"
            ></img>
            <a href="/why-choose-dfs/being-a-dfs-dealer/">Being a DFS Dealer</a>
          </div>
          <div className={styles.card}>
            <img
              src={NationwideLocation_Thumbnail}
              alt="Two men working together"
            ></img>
            <a href="/why-choose-dfs/manufacturing-distribution-locations/">
              8 production facilities nationwide
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
