import React from "react";
import Layout from "../../other/layout";
import WhyChooseDFS from "../../components/AC-StaticPages/why-choose-dfs/WhyChooseDFS";
import SEO from "../../other/seo";

export default function whyChooseDFS() {
  return (
    <Layout>
      <SEO title="Why Choose DFS" />

      <WhyChooseDFS />
    </Layout>
  );
}
